




















































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab1Procedure',
  components: {StembleLatex, TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        procedure: null,
        observations: null,
        language: 'en',
      },
      questions: [
        {
          en: 'This section should describe what YOU actually did and observed - not necessarily what is stated in this manual. If no changes to the given procedure were made, you may state “As outlined in the lab manual”, followed by the reference to the procedure in the lab manual. The procedure is best listed in point form.',
          fr: 'Cette section doit décrire ce que VOUS avez réellement fait et observé, pas nécessairement ce qui est indiqué dans le manuel. Si vous avez suivi la procédure donnée exactement, vous pouvez écrire « comme décrit dans le manuel » et ensuite donner comme référence le manuel de laboratoire.',
        },
        {
          en: 'Report all observations as they occurred - not as described for the average experiment in this manual. If you have heated a solution for 35 minutes instead of the 30 minutes suggested, record 35 minutes. Describe any changes made to the lab procedure. You should include the visual appearance of reagents (e.g., pale yellow liquid, colorless crystals, white powder) and any observations made during the reaction (colour change, bubbles, smells, etc.).',
          fr: "Signalez toutes les observations telles qu'elles se sont produites - non pas comme décrit pour l'expérience moyenne dans ce manuel. Si vous avez chauffé une solution pendant 35 minutes au lieu des 30 minutes suggérées, enregistrez 35 minutes. Décrivez toutes les modifications apportées à la procédure de laboratoire. Vous devez inclure l'apparence visuelle des réactifs (par exemple, liquide jaune pâle, cristaux incolores, poudre blanche) et toutes les observations faites pendant la réaction (changement de couleur, formation de bulles, odeurs, etc.).",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
